import React from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import {
  Box, CircularProgress,
  Typography, useTheme,
} from '@mui/material'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextFieldComponent from '@base/forms/TextField'
import SelectMenuItemComponent from '@base/menus/SelectMenuItem'
import SelectMenuListComponent from '@base/menus/SelectMenuList'

import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import ChevronIcon from '@icons/flow/chevron.icon'
import CloseIcon from '@icons/close.icon'

import useStyles from './AutocompleteSelectField.styles'

export interface AutocompleteSelectFieldProps<T> extends Omit<AutocompleteProps<any, false, false, any>, 'renderInput' | 'options'> {
  /**
   * The input name. Same as id if not provided.
   * Some form libraries require a name attribute.
   */
  name: string,
  /**
   * The options to be shown
   */
  options: T[],
  /**
   * onChange callback
   * @param e Event
   * @param selectedValue Selected value
   * @param reason Reason
   */
  handleChangeCallback(e: React.ChangeEvent<any>, selectedValue: T, reason: any): any,
  /**
   * Gets the label of the option
   * @param option Option
   * @returns Label
   */
  getOptionLabel: (option: T) => string,
  /**
   * The label content.
   */
  label: string
  /**
   * If true, the label will be hidden.
   */
  hideLabel?: boolean
  /**
   * The input placeholder.
   */
  placeholder?: string
  /**
   * Input validation errors.
   */
  errors?: string | NonNullable<React.ReactNode>
  /**
   * Indicates if the input has been touched.
   */
  touched?: boolean
  /**
   * If true, loading spinner will be shown.
   */
  loading?: boolean
  /**
   * Help text to be displayed in a tooltip.
   */
  floatingHelp?: string | NonNullable<React.ReactNode>
}

const AutocompleteSelectFieldComponent = <T extends {}> ({
  name,
  value,
  handleChangeCallback,
  getOptionLabel,
  label,
  hideLabel,
  errors,
  touched,
  options,
  disabled,
  floatingHelp,
  placeholder,
  noOptionsText,
  loading,
  ...rest
}: AutocompleteSelectFieldProps<T>) => {
  const theme = useTheme()
  const intl = useIntl()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const errorMessages = touched && errors
  const { classes } = useStyles({
    floatingHelp: Boolean(floatingHelp),
  })

  const handleChange = (e: React.ChangeEvent<any>, selectedValue: T, reason: any) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      selectedValue,
      router: params,
    })

    handleChangeCallback && handleChangeCallback(e, selectedValue, reason)
  }

  return (
    <Box
      data-testid={AutocompleteSelectFieldComponent.name}
    >
      <Autocomplete
        id={name}
        autoHighlight={false}
        autoSelect={false}
        fullWidth={true}
        disablePortal={false}
        disableCloseOnSelect={false}
        freeSolo={false}
        clearIcon={<CloseIcon />}
        popupIcon={<ChevronIcon />}
        noOptionsText={(
          <Typography
            variant='body1'
            px='12px'
            color={theme.palette.new.business_black_40}
          >
            {noOptionsText || intl.formatMessage({ id: 'common.dropdown.noOptions' })}
          </Typography>
        )}
        classes={{
          paper: classes.paper,
          noOptions: classes.noOptions,
          inputRoot: classes.inputRoot,
          endAdornment: classes.endAdornment,
          root: classes.root,
          popper: classes.popper,
        }}
        options={options}
        value={value}
        disabled={disabled}
        onChange={(event, newValue: T, reason) => {
          handleChange(event, newValue, reason)
        }}
        getOptionLabel={getOptionLabel}
        ListboxComponent={SelectMenuListComponent}
        renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: T, { selected } : { selected: boolean }) => {
          return (
            <SelectMenuItemComponent
              {...props}
              selected={selected}
            >
              {getOptionLabel(option)}
            </SelectMenuItemComponent>
          )
        })}
        renderInput={(inputParams) => (
          <TextFieldComponent
            {...inputParams.InputProps}
            inputProps={inputParams.inputProps}
            name={name}
            label={label}
            disabled={disabled}
            hideLabel={hideLabel}
            touched={touched}
            placeholder={disabled ? '' : placeholder}
            errors={errorMessages}
            floatingHelp={floatingHelp}
            endAdornment={(
              <>
                {loading ? <CircularProgress color='inherit' size={16} sx={{ opacity: '0.6' }} /> : null}
                {inputParams.InputProps.endAdornment}
              </>
            )}
          />
        )}
        loading={loading}
        {...rest}
      />
    </Box>
  )
}

export default AutocompleteSelectFieldComponent
