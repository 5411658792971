import FolderIcon from '@icons/folder.icon'
import FileIcon from '@icons/file.icon'
import FileZipIcon from '@icons/fileZip.icon'
import FileImgIcon from '@icons/fileImg.icon'
import FileCodeIcon from '@icons/fileCode.icon'

export const AUTO_REFRESH_INTERVAL_IN_MS = 10000
export const MAX_FILE_NAME_LENGTH = 90

export enum PROCESSING_STATES {
  NO_PROCESSING = 'NO_PROCESSING', // File upload via a legacy route, legacy files do not get processed
  QUEUED = 'QUEUED', // The file upload completed and the file is currently waiting to be processed
  RUNNING = 'RUNNING', // The file is currently being validated and processed
  SUCCEEDED = 'SUCCEEDED', // The file has been processed successfully and is valid (parsable, expected columns present, ...)
  FAILED = 'FAILED', // The file has been processed and errors have been found. Details on the errors can be found in the attached CsvValidationResult.
  ERROR = 'ERROR', // Workflow failed to process file
}

export enum VALIDATION_ERRORS {
  // CSV validaiton types
  PARSABLE = 'PARSABLE',
  EMPTY_COLUMN_NAMES = 'EMPTY_COLUMN_NAMES',
  DUPLICATE_COLUMNS = 'DUPLICATE_COLUMNS',
  MISSING_COLUMNS = 'MISSING_COLUMNS',
  ADDITIONAL_COLUMNS = 'ADDITIONAL_COLUMNS',
  INVALID_COLUMNS = 'INVALID_COLUMNS',
  INVALID_ROWS = 'INVALID_ROWS',

  // ELT validation types
  NOT_NULL = 'NOT_NULL',
  CATEGORIES = 'CATEGORIES',
  UNIQUE = 'UNIQUE',
  VALUE_RANGE = 'VALUE_RANGE',
  NUMBER_FORMAT = 'NUMBER_FORMAT',
  DATE_FORMAT = 'DATE_FORMAT',
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  PLAIN_TEXT = 'PLAIN_TEXT',
}

export enum SUPPORTED_PREVIEW_FILE_TYPES {
  PNG = 'png',
  SVG = 'svg',
  JSON = 'json',
  TXT = 'txt',
  CSV = 'csv',
  HTML = 'html',
}

export enum FILE_PREVIEW_TYPE {
  TRAINING_FILES = 'TRAINING_FILES',
  FILE_MANAGER = 'FILE_MANAGER',
  OUTPUT_FILES = 'OUTPUT_FILES',
}

export const LEGACY_FILES_IDENTIFIER = 'general-file-upload'

export const MAX_PREVIEW_FILE_SIZE_IN_BYTES = 200 * 1024 * 1024

export const MAX_EDIT_FILE_SIZE_IN_BYTES = 20 * 1024 * 1024

export const EMPTY_CSV_VALUE = 'N/A'

export const FILE_EXTENSION_TO_ICON_MAP = {
  default: FileIcon,
  folder: FolderIcon,
  txt: FileIcon,
  zip: FileZipIcon,
  html: FileCodeIcon,
  xls: FileIcon,
  xlsx: FileIcon,
  xml: FileIcon,
  yml: FileIcon,
  svg: FileImgIcon,
  sql: FileIcon,
  png: FileImgIcon,
  pdf: FileIcon,
  js: FileCodeIcon,
  docx: FileIcon,
  doc: FileIcon,
  csv: FileIcon,
  json: FileCodeIcon,
}

export enum FILE_BROWSER_SORTING_ORDER_OPTIONS {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FILE_BROWSER_SORTING_OPTIONS {
  NAME = 'name',
  SIZE = 'sizeInBytes',
  CREATED_AT = 'createdAt',
}

export const FILE_BROWSER_SORTING_OPTIONS_TO_LABEL_MAP = {
  [FILE_BROWSER_SORTING_OPTIONS.NAME]: 'fileManager.header.sorting.name',
  [FILE_BROWSER_SORTING_OPTIONS.SIZE]: 'fileManager.header.sorting.size',
  [FILE_BROWSER_SORTING_OPTIONS.CREATED_AT]: 'fileManager.header.sorting.date',
}

export const FILE_BROWSER_SORTING_OPTIONS_LIST = [
  {
    value: FILE_BROWSER_SORTING_OPTIONS.NAME,
    labelKey: FILE_BROWSER_SORTING_OPTIONS_TO_LABEL_MAP[FILE_BROWSER_SORTING_OPTIONS.NAME],
    numeric: false,
  },
  {
    value: FILE_BROWSER_SORTING_OPTIONS.SIZE,
    labelKey: FILE_BROWSER_SORTING_OPTIONS_TO_LABEL_MAP[FILE_BROWSER_SORTING_OPTIONS.SIZE],
    numeric: true,
  },
  {
    value: FILE_BROWSER_SORTING_OPTIONS.CREATED_AT,
    labelKey: FILE_BROWSER_SORTING_OPTIONS_TO_LABEL_MAP[FILE_BROWSER_SORTING_OPTIONS.CREATED_AT],
    numeric: true,
  },
]

export const FILE_BROWSER_DEFAULT_SORTING_KEY = FILE_BROWSER_SORTING_OPTIONS.NAME
export const FILE_BROWSER_DEFAULT_SORTING_ORDER = FILE_BROWSER_SORTING_ORDER_OPTIONS.ASC
export const FILE_BROWSER_CSV_EMPTY_COLUMN_NAME_PREFIX = 'unnamed_column_'
