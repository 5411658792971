import { PIPELINES } from '@constants/api-provider.constants'
import { checkApiForError } from '@utils/redux.utils'
import apiProvider from '@redux/api-provider'

export const updatePipelineDependsOn = (payload: Pipelines.APIUpdatePipelineDependsOnRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/update-depends-on', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const listPipeplineGraphs = (payload: Pipelines.APIListPipelineGraphsRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/composite-pipeline/resolve-pipeline-graphs', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data as Pipelines.APIListPipelineGraphsResponse
    })
}

export const reportPipelineDelivery = (payload: Pipelines.APIPipelineReportDeliveryRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/delivery/report', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const updateDemandPipelineResources = (payload: Pipelines.APIDemandPipelineUpdateResourcesRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/demand-pipeline/update-resources', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const updateOptimizationPipelineResources = (payload: Pipelines.APIOptimizationPipelineUpdateResourcesRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/optimization-pipeline/update-resources', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const enablePipeline = (payload: Pipelines.APIPipelineActionRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/enable', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const disablePipeline = (payload: Pipelines.APIPipelineActionRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/disable', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const deletePipeline = (payload: Pipelines.APIPipelineActionRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/delete', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const triggerPipeline = (payload: Pipelines.APIPipelineActionRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/trigger', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const updateDemandConfig = (payload: Pipelines.APIUpdateDemandConfigRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/demand-pipeline/update-config', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const updateDemandExecutionConfig = (payload: Pipelines.APIUpdateDemandExecutionConfigRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/demand-pipeline/update-execution-config', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const getPipelinesList = (payload: Pipelines.APIPipelinesListRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/list/details', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return (data?.data?.pipelines || []) as Pipelines.APIPipelinesListResponseItem[]
    })
}

export const getDemandPipelineList = (payload: Pipelines.APIDemandPipelineListRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/demand-pipeline/list', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data?.pipelines as Pipelines.APIDemandPipelineListResponse[]
    })
}

export const getDemandPipelineConfig = (payload: Pipelines.APIDemandPipelineConfigRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/demand-pipeline/get-config', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data as Pipelines.APIDemandPipelineConfigResponse
    })
}

export const updatePipelineExecutionSchedule = (payload: Pipelines.APIUpdatePipelineExecutionScheduleRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/update-execution-schedule', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const updatePipelineDeliverySchedule = (payload: Pipelines.APIUpdatePipelineDeliveryScheduleRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/delivery/schedule/update', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data as Pipelines.APIUpdatePipelineDeliveryScheduleResponse
    })
}

export const deletePipelineDeliverySchedule = (payload: Pipelines.APIDeletePipelineDeliveryScheduleRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/delivery/schedule/remove', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data as Pipelines.APIDeletePipelineDeliveryScheduleResponse
    })
}

export const getExpectedDeliveryHistoryList = (payload: Pipelines.APIExpectedDeliveryHistoryListRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/expected-delivery/history/list', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return (data?.data?.expectedDeliveryHistory || []) as Pipelines.APIExpectedDeliveryHistoryListResponseItem[]
    })
}

export const setPipelineDeliversToCustomer = (payload: Pipelines.APISetPipelineDeliversToCustomerRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/pipeline/update-delivers-to-customer', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data as Pipelines.APISetPipelineDeliversToCustomerResponse
    })
}

export const fetchCompositePipelineStatus = ({
  useCaseId,
}: Pipelines.APIGetCompositePipelineStatusRequest) => {
  return apiProvider
    .getApi(PIPELINES)
    .post('v1/composite-pipeline/run-status/get', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      return {
        useCaseId,
        ...data?.data,
      }
    })
    .catch((error) => {
      return {
        useCaseId,
        status: null,
        lastSuccessfulRun: null,
        lastRun: null,
      }
    })
}

export const startCompositePipeline = ({
  useCaseId,
}: Pipelines.APIStartCompositePipelineRequest) => {
  return apiProvider
    .getApi(PIPELINES)
    .post('v1/composite-pipeline/trigger', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data
    })
}

export const stopCompositePipeline = ({
  useCaseId,
}: Pipelines.APIStopCompositePipelineRequest) => {
  return undefined
}

export const createELTPipeline = (payload: Pipelines.APICreateELTPipelineRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/elt-pipeline/create', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const createOptimizationPipeline = (payload: Pipelines.APICreateOptimizationPipelineRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/optimization-pipeline/create', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const createCompositePipeline = (payload: Pipelines.APICreateCompositePipelineRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/composite-pipeline/create', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const createPostprocessingPipeline = (payload: Pipelines.APICreatePostprocessingPipelineRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/postprocessing-pipeline/create', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}

export const createDemandPipeline = (payload: Pipelines.APICreateDemandPipelineRequest) => {
  return apiProvider.getApi(PIPELINES).post('v1/demand-pipeline/create', payload)
    .then(({ data }) => {
      checkApiForError(data)
    })
}
