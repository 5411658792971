import React, {
  useCallback,
  useLayoutEffect, useMemo,
  useState,
} from 'react'

import {
  useRouteMatch,
  generatePath,
  useHistory,
} from 'react-router-dom'

import { PIPELINES_PATH, USE_CASE_PIPELINES_PATH, USE_CASE_PIPELINE_OVERVIEW_PATH } from '@constants/routes.constants'
import { DATA_GRIDS } from '@constants/data-grid.constants'

import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GRID_ACTIONS_COLUMN_TYPE,
} from '@mui/x-data-grid-premium'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getPipelinesByUseCaseId, isFetchingPipelines, isSubmittingPipeline } from '@redux/modules/pipelines/pipelines.selectors'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { getDataGridId } from '@utils/data-grid.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { CREATE_PIPELINE_MODAL_NAME, PIPELINE_CONFIGURATION_MODAL_NAME, PIPELINE_REPORT_DELIVERY_MODAL_NAME } from '@constants/modals.constants'
import { Box } from '@mui/material'

import PlayIcon from '@icons/play.icon'
import GearIcon from '@icons/gear.icon'

import {
  cellValueGetter,
  generateActionsColumnDefinition,
  generateClickableLinkColumnDefinition,
  generateRegularColumnDefinition,
  generateCopyButtonColumnDefinition,
  generateSingleSelectColumnDefinition,
  generateChipColumnDefinition,
  generateBooleanColumnDefinition,
  generateDateTimeColumnDefinition,
  generateFileSizeColumnDefinition,
} from '@utils/data-grid-cells.utils'

import PipelineScheduleConfigurationModalContainer from '@containers/modals/pipeline-schedule-configuration-modal'
import PipelineReportDeliveryModalContainer from '@containers/modals/pipeline-report-delivery-modal'
import CreatePipelineModalContainer from '@containers/modals/create-pipeline-modal'

import PipelineConfigurationModalContainer, {
  PipelineConfigurationModalDetails,
} from '@containers/modals/pipeline-configuration-modal/PipelineConfigurationModal.container'

import DeliveryIcon from '@icons/delivery.icon'
import ViewIcon from '@icons/view.icon'
import PageLayoutContainer from '@containers/application/PageLayout'
import DataGridComponent from '@base/datagrid/data-grid/DataGrid.component'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import DataGridTooltipCellComponent from '@base/datagrid/data-grid-cells/data-grid-tooltip-cell'
import CallToActionButtonComponent from '@base/pagebar/CallToActionButton'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import DataGridBooleanCellComponent from '@base/datagrid/data-grid-cells/data-grid-boolean-cell'

import {
  deletePipelineAction,
  requestPipelinesListAction,
  triggerPipelineAction,
} from '@redux/modules/pipelines/pipelines.actions'

import {
  PIPELINE_DELIVERY_STATUS_OPTIONS,
  PIPELINE_VIEW_TYPES,
  getChipBgColorByStatus,
  getPipelineTypeBgColor,
} from '@constants/pipelines.constants'

import useGridInitialState from '@hooks/useGridInitialState.hook'

import ButtonComponent from '@base/buttons/Button/Button.component'

interface ConfirmationDialogState {
  pipelineId: string
  pipelineName: string
  action: 'delete' | 'trigger'
}

const PipelinesContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase

  const isAdmin = useSelector((state) => getIsAdmin(state))
  const isFetching = useSelector((state) => isFetchingPipelines(state))
  const isSubmitting = useSelector((state) => isSubmittingPipeline(state))
  const useCase = useSelector((state) => getUseCaseItem(state))
  const pipelinesList = useSelector((state) => getPipelinesByUseCaseId(state, useCaseId))

  const [confirmationDialogState, setConfirmationDialogState] = useState<ConfirmationDialogState | null>(null)
  const tableName = useCaseId ? DATA_GRIDS.USE_CASE_PIPELINES : DATA_GRIDS.GLOBAL_PIPELINES
  const tableId = getDataGridId(tableName, 1)

  const pageTitle = useCaseId ? (
    intl.formatMessage({ id: 'pipelines.browser_tab.title.useCase' }, { name: useCase?.name })
  ) : (
    intl.formatMessage({ id: 'pipelines.browser_tab.title' })
  )

  useLayoutEffect(() => {
    if (useCaseId) {
      dispatch(
        fetchUseCaseAction({
          useCaseId,
        }),
      )
    }

    dispatch(
      requestPipelinesListAction({
        silent: false,
      }),
    )
  }, [dispatch, useCaseId])

  const handleConfigurationDialogClose = useCallback(() => {
    setConfirmationDialogState(null)
  }, [])

  const handleConfigurationDialogSubmit = useCallback(() => {
    if (!confirmationDialogState) {
      return
    }

    switch (confirmationDialogState.action) {
      case 'delete':
        dispatch(deletePipelineAction({ pipelineId: confirmationDialogState.pipelineId }))
        break
      case 'trigger':
        dispatch(triggerPipelineAction({ pipelineId: confirmationDialogState.pipelineId }))
        break
      default:
        break
    }

    setConfirmationDialogState(null)
  }, [dispatch, confirmationDialogState])

  const getOnUseCaseClickHandler = useCallback((params: GridRenderCellParams<Pipelines.ReduxPipelineItem>) => {
    return () => {
      history.push(generatePath(USE_CASE_PIPELINES_PATH, { usecase: params.row.useCaseId }))
    }
  }, [history])

  const getActionItems = useCallback((params: GridRowParams<Pipelines.ReduxPipelineItem>) => {
    return [
      <DataGridActionButtonComponent
        name='pipelineHistory'
        icon={<ViewIcon />}
        label={intl.formatMessage({ id: 'pipelines.table.action.history' })}
        id={params.id}
        onClick={() => {
          history.push({
            pathname: generatePath(USE_CASE_PIPELINE_OVERVIEW_PATH, {
              usecase: params.row.useCaseId,
              pipeline: params.row.pipelineId,
            }),
            search: `previousView=${useCaseId ? PIPELINE_VIEW_TYPES.USECASE : PIPELINE_VIEW_TYPES.GLOBAL}`,
          })
        }}
      />,
      <DataGridActionButtonComponent
        name='tiggerPipeline'
        icon={<PlayIcon />}
        label={intl.formatMessage({ id: 'pipelines.table.trigger' })}
        id={params.id}
        onClick={() => {
          setConfirmationDialogState({
            pipelineId: params.row.pipelineId,
            pipelineName: params.row.name,
            action: 'trigger',
          })
        }}
      />,
      <DataGridActionButtonComponent
        name='reportDelivery'
        icon={<DeliveryIcon />}
        label={intl.formatMessage({ id: 'pipelines.table.delivery' })}
        id={params.id}
        onClick={() => {
          dispatch(
            setPrimaryModalPageName({
              primaryModalPage: PIPELINE_REPORT_DELIVERY_MODAL_NAME,
              modalDetails: {
                returnTo: '',
                pipeline: params.row,
              } as PipelineConfigurationModalDetails,
            }),
          )
        }}
      />,
      <DataGridActionButtonComponent
        name='configurePipeline'
        icon={<GearIcon />}
        label={intl.formatMessage({ id: 'common.tables.actions.configure' })}
        id={params.id}
        onClick={() => {
          dispatch(
            setPrimaryModalPageName({
              primaryModalPage: PIPELINE_CONFIGURATION_MODAL_NAME,
              modalDetails: {
                returnTo: '',
                pipeline: params.row,
              } as PipelineConfigurationModalDetails,
            }),
          )
        }}
      />,
    ]
  }, [
    intl,
    dispatch,
    history,
    useCaseId,
  ])

  const columns = useMemo<GridColDef[]>(() => [
    generateRegularColumnDefinition({
      intl,
      field: 'companyName',
      headerName: intl.formatMessage({ id: 'pipelines.table.companyName' }),
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
    }),
    generateClickableLinkColumnDefinition({
      field: 'useCaseName',
      headerName: intl.formatMessage({ id: 'pipelines.table.usecaseName' }),
      getOnClick: getOnUseCaseClickHandler,
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
    }),
    generateChipColumnDefinition({
      intl,
      field: 'type',
      headerName: intl.formatMessage({ id: 'pipelines.table.type' }),
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
      uppercase: true,
      getBgColorByValue: getPipelineTypeBgColor,
    }),
    generateBooleanColumnDefinition({
      intl,
      field: 'enabled',
      headerName: intl.formatMessage({ id: 'pipelines.table.pipelineStatus' }),
      activeLabel: intl.formatMessage({ id: 'common.enabled' }),
      inactiveLabel: intl.formatMessage({ id: 'common.disabled' }),
    }),
    generateBooleanColumnDefinition({
      intl,
      field: 'deliversToCustomer',
      headerName: intl.formatMessage({ id: 'pipelines.table.deliversToCustomer' }),
      activeLabel: intl.formatMessage({ id: 'common.yes' }),
      inactiveLabel: intl.formatMessage({ id: 'common.no' }),
    }),
    generateSingleSelectColumnDefinition({
      field: 'lastDeliveryStatus',
      headerName: intl.formatMessage({ id: 'pipelines.table.lastDeliveryStatus' }),
      valueOptions: PIPELINE_DELIVERY_STATUS_OPTIONS,
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
      getBgColorByValue: getChipBgColorByStatus,
    }),
    generateDateTimeColumnDefinition({
      intl,
      field: 'lastDeliveryDate',
      headerName: intl.formatMessage({ id: 'pipelines.table.lastDeliveryDate' }),
      gridColDefOverrides: {
        renderCell: (params) => {
          return cellValueGetter({
            params,
            customCell: (
              <DataGridTooltipCellComponent
                label={params.formattedValue || intl.formatMessage({ id: 'pipelines.na' })}
              />
            ),
          })
        },
      },
    }),
    generateCopyButtonColumnDefinition({
      field: 'name',
      headerName: intl.formatMessage({ id: 'pipelines.table.name' }),
    }),
    generateChipColumnDefinition({
      intl,
      field: 'executionSchedule',
      headerName: intl.formatMessage({ id: 'pipelines.table.executionSchedule' }),
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
      getTooltip: (params) => intl.formatMessage({ id: 'pipelines.table.cronText' }, { cron: params.row.executionScheduleDate }),
    }),
    generateDateTimeColumnDefinition({
      intl,
      field: 'nextExecutionDate',
      headerName: intl.formatMessage({ id: 'pipelines.table.nextExecutionDate' }),
      gridColDefOverrides: {
        renderCell: (params) => {
          return cellValueGetter({
            params,
            customCell: (
              <DataGridTooltipCellComponent
                label={params.formattedValue || intl.formatMessage({ id: 'pipelines.na' })}
                tooltip={intl.formatMessage({ id: 'pipelines.table.cronTimezone' })}
              />
            ),
          })
        },
      },
    }),
    generateChipColumnDefinition({
      intl,
      field: 'deliverySchedule',
      headerName: intl.formatMessage({ id: 'pipelines.table.deliverySchedule' }),
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
      getTooltip: (params) => intl.formatMessage({ id: 'pipelines.table.cronText' }, { cron: params.row.deliveryScheduleDate }),
    }),
    generateDateTimeColumnDefinition({
      intl,
      field: 'nextDeliveryDate',
      headerName: intl.formatMessage({ id: 'pipelines.table.nextDeliveryDate' }),
      gridColDefOverrides: {
        renderCell: (params) => {
          return cellValueGetter({
            params,
            customCell: (
              <DataGridTooltipCellComponent
                label={params.formattedValue || intl.formatMessage({ id: 'pipelines.na' })}
                tooltip={intl.formatMessage({ id: 'pipelines.table.cronTimezoneTemporal' })}
              />
            ),
          })
        },
      },
    }),
    generateFileSizeColumnDefinition({
      intl,
      field: 'memoryMb',
      headerName: intl.formatMessage({ id: 'pipelines.table.memoryMb' }),
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
      gridColDefOverrides: {
        valueGetter: (params) => {
          // convert MB to bytes
          return params.value ? params.value * 1024 * 1024 : null
        },
      },
    }),
    generateBooleanColumnDefinition({
      intl,
      field: 'gpu',
      headerName: intl.formatMessage({ id: 'pipelines.table.gpu' }),
      gridColDefOverrides: {
        renderCell: (params) => {
          if (params.value === undefined || params.value === null) {
            return intl.formatMessage({ id: 'pipelines.na' })
          }

          return (
            <DataGridBooleanCellComponent
              params={params}
              booleanLabels={{
                active: intl.formatMessage({ id: 'common.yes' }),
                inactive: intl.formatMessage({ id: 'common.no' }),
              }}
            />
          )
        },
      },
    }),
    generateCopyButtonColumnDefinition({
      field: 'companyId',
      headerName: intl.formatMessage({ id: 'pipelines.table.companyId' }),
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
    }),
    generateCopyButtonColumnDefinition({
      field: 'useCaseId',
      headerName: intl.formatMessage({ id: 'pipelines.table.usecaseId' }),
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
    }),
    generateCopyButtonColumnDefinition({
      field: 'pipelineId',
      headerName: intl.formatMessage({ id: 'pipelines.table.pipelineId' }),
      naLabel: intl.formatMessage({ id: 'pipelines.na' }),
    }),
    generateActionsColumnDefinition({
      getActionItems,
      numberOfActions: 4,
    }),
  ], [
    intl,
    getActionItems,
    getOnUseCaseClickHandler,
  ])

  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    columns: {
      columnVisibilityModel: {
        useCaseId: !(useCaseId),
        useCaseName: !(useCaseId),
        companyId: !(useCaseId),
        companyName: !(useCaseId),
      },
    },
    sorting: {
      sortModel: [{ field: 'nextExecutionDate', sort: 'asc' }],
    },
  })

  if (!isAdmin) {
    return null
  }

  const onRefreshButtonClick = () => {
    dispatch(
      requestPipelinesListAction({
        silent: false,
      }),
    )
  }

  const onCreateButtonClick = () => {
    dispatch(
      setPrimaryModalPageName(CREATE_PIPELINE_MODAL_NAME),
    )
  }

  const pageBarRightSideBlocks = (
    <>
      <ButtonComponent
        name='refreshPipelinesButton'
        onClick={onRefreshButtonClick}
        loading={isFetching}
        label={intl.formatMessage({ id: 'common.refresh' })}
        color='secondary'
      />

      <CallToActionButtonComponent
        name='createPipelineButton'
        onClick={onCreateButtonClick}
        label={intl.formatMessage({ id: 'pipelines.create' })}
      />
    </>
  )

  return (
    <PageLayoutContainer
      title={pageTitle}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      customTitle={pageTitle}
      backToPath={useCaseId ? PIPELINES_PATH : undefined}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={tableName}
        columns={columns}
        loading={isSubmitting || isFetching}
        rows={pipelinesList}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        getRowId={(row) => row.pipelineId}
        initialState={initialState}
      />

      <PipelineConfigurationModalContainer />

      <PipelineScheduleConfigurationModalContainer />

      <PipelineReportDeliveryModalContainer />

      <CreatePipelineModalContainer />

      <TextConfirmationDialogComponent
        open={Boolean(confirmationDialogState) && isAdmin}
        onClose={handleConfigurationDialogClose}
        onSubmit={handleConfigurationDialogSubmit}
        confirmationText={confirmationDialogState?.pipelineName}
        confirmationInputLabel={intl.formatMessage({ id: 'pipelines.list.confirmation.trigger_pipeline_dialog.confirmation' })}
        submitButtonLabel={intl.formatMessage({ id: 'common.modal.button.submit' })}
        description={
          intl.formatMessage({ id: 'pipelines.list.confirmation.trigger_pipeline_dialog.content' }, {
            action: <Box component='strong'>{confirmationDialogState?.action}</Box>,
            name: <Box component='strong'>{confirmationDialogState?.pipelineName}</Box>,
          })
        }
      />
    </PageLayoutContainer>
  )
}

export default PipelinesContainer
